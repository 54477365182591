.revenue-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px;
    background-color: #ffffff;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    border: #000;
  }
  .containerR {
    width: 90%;
    padding: 20px;
  }
  
  .control {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dropdown {
    margin: 0 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .table thead th[colspan] {
    color: #ff8c00;
    font-weight: bold;
    font-size: 1.3em;
    text-align: center;
    padding: 10px;
    border: none;
    background-color: transparent;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th,
  .table td {
    padding: 8px;
    text-align: center;
  }
  
  .table th[colspan="2"] {
    text-align: center;
  }
  
  .table thead th {
    border-bottom: 1px solid #ddd;
  }
  
  .download-btn,
  .add-revenue-btn {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #1e306d;
    border-radius: 5px;
    background-color: #1e306d;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .download-btn:hover,
  .add-revenue-btn:hover {
    background-color: #16255a;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 500px;
    margin: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 20px;
  }
  
  .modal-close {
    cursor: pointer;
    font-size: 22px;
  }
  
  .revenue-modal-body .revenue-form-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .revenue-modal-body form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .revenue-modal-body .revenue-form-group {
    width: 48%;
  }
  
  .revenue-modal-body .form-control {
    width: 100%;
  }
  
  .target-input,
  .actual-input {
    font-size: 16px;
    width: 100px;
  }
  
  .target-input {
    margin-right: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }
  
  .modal-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-button.primary {
    background-color: #1e306d;
    color: #fff;
  }
  
  .modal-button.secondary {
    background-color: #ccc;
  }
  
  .modal-body .form-group {
    margin-bottom: 0px;
  }
  
  .modal-body .form-label {
    margin-bottom: 5px;
    display: block;
    margin-left: -7px;
    margin-top: 10px;
  }
  
  .modal-body .d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-body .form-control {
    flex: 0 1 45%;
    margin-right: 10px;
    font-size: 16px;
    padding: 8px 12px;
  }
  
  .modal-body .form-control:last-child {
    margin-right: 0;
  }
  
  .modal-body .form-group + .form-group {
    margin-top: 20px;
  }
  
  @media (max-width: 576px) {
    .modal-body .d-flex {
      flex-direction: column;
      align-items: stretch;
    }
  
    .modal-body .form-control {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .modal-body .form-control:last-child {
      margin-bottom: 0;
    }
  }
  
  @media (max-width: 768px) {
    .control {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .dropdown {
      margin: 10px 0;
    }
  
    .download-btn,
    .add-revenue-btn {
      width: 100%;
      justify-content: center;
      margin-bottom: 10px;
    }
  
    .modal-content {
      width: 90%;
    }
  }
  