.super-admin-table-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px;
    background-color: #FFFFFF;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
  }
  
  .super-admin-booking-table-container {
    max-width: 100%; 
    background-color: #f9f9f9;
    margin-bottom: 5%;
    padding: 3% 1.5%;
    border: 1px solid #E6E9F4;
    border-radius: 6px;
  }
  
  .super-admin-booking-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .super-admin-booking-table th,
  .super-admin-booking-table td {
    padding: 12px 15px;
    text-align: left; /* Ensures all table contents are left-aligned */
    border-bottom: 1px solid #ddd;
  }
  
  
  .super-admin-booking-pagination {
    display: flex;
    justify-content: left;
    margin-top: 20px;
  }
  
  .super-admin-booking-pagination button {
    margin: 0 5px;
    padding: 8px 16px;
    border: none;
    background-color: #22326E;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .super-admin-active-page {
    background-color: #22326E;
  }
  
  .super-admin-booking-results-count {
    text-align: right;
    margin-top: 15px;
    margin-right: 5%;
    font-size: 1rem; 
    color: #555;
  }
  
  .super-admin-booking-table-row-hover {
    cursor: pointer;
  }
  
  .super-admin-booking-table-row-hover:hover {
    background: rgb(0, 0, 0, 0.1);
  }
  