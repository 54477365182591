.admin-tableWrapper {
  display: flex;
  margin-top: 20px;
  overflow-x: auto;
}

.admin-booking-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  border: #22326e;
}

.admin-whiteBackgroundTable {
  background-color: #fff;
  color: #1f1b1b;
  width: 100%;
  margin: 0 20px;
  padding: 20px;
}

.admin-whiteBackgroundTable th {
  background-color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #a3a3a3;
}

.admin-whiteBackgroundTable td {
  font-size: 16px;
  justify-content: center;
  align-items: center;
  padding: 30px 0 0 0;
  margin-left: 1px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.admin-record-action {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  flex-direction: row-reverse;
}

.admin-record-action a {
  display: flex;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 3px;
  transition: all 0.3s ease-in-out;
}

.admin-record-action a:nth-child(1) {
  color: #e35205;
}

.admin-record-action a:nth-child(2) {
  color: #4d5b8c;
}

.admin-record-action a:hover {
  filter: brightness(0.8);
}

.admin-buttonsearchWrapper {
  display: flex;
  width: 97%;
  margin: 0 auto;
  justify-content: space-between;
}

.admin-search-wrapper {
  width: 300px;
  border: none !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color: transparent;
  border: 2px solid #a3a3a3;
  outline: none;
}

.admin-search-wrapper input {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #a3a3a3 !important;
}

.admin-search-wrapper input:focus {
  background-color: #d4d4d4;
  border: 1px solid black;
}
.booking-table-title {
  text-align: left;
}

.booking-table {
  width: 100%;
  border-collapse: collapse;
}

.booking-table th,
.booking-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.booking-table-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem; 
}

.booking-action-icons button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.booking-edit-button {
  color: #4caf50;
}

.booking-delete-button {
  color: #f44336;
}

.booking-pagination {
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.booking-pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  background-color: #22326e;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.booking-pagination button.active-page {
  background-color: #22326e;
}

.booking-pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.booking-results-count {
  text-align: right;
  margin-top: 15px;
  margin-right: 5%;
  font-size: 1rem;
  color: #555;
}

.booking-table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.booking-search-bar,
.booking-filter-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.booking-add-button {
  padding: 8px 9px;
  background-color: #22326e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.booking-download-button {
  width: 150px;
  height: 100%;
  font-family: "Roman";
  color: #fff;
  border: 1px solid #22326e;
  background-color: #22326e;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.admin-button:hover {
  background-color: #d4d4d4;
  border-color: black;
}
.add-admin-button:hover {
  background-color: #d4d4d4;
}

/* Dropdown-specific style can remain */
.admin-button select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.admin-button input[type="text"],
.admin-button input[type="date"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: none;
}
.booking-container {
  border: 1px solid #22326e;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}
.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  vertical-align: middle;
}

.admin-table-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
}
.booking-table-container {
  max-width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 5%;
  padding: 3% 1.5%;
  border: 1px solid #e6e9f4;
  border-radius: 6px;
  text-align: left;
}
.booking-table-title {
  text-align: left;
}

.admin-booking-table {
  width: 100%;
  border-collapse: collapse;
}

.booking-table th,
.booking-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.booking-table-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem; 
}

.booking-action-icons button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.booking-edit-button {
  color: #4caf50;
}

.booking-edit-buttonn {
  color: #525050;
}

.booking-delete-button {
  color: #f44336;
}

.booking-pagination {
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.booking-pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  background-color: #22326e;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.booking-pagination button.active-page {
  background-color: #E35205;
}

.booking-pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.booking-results-count {
  text-align: right;
  margin-top: 15px;
  margin-right: 5%;
  font-size: 1rem;
  color: #555;
}

.booking-table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.booking-right-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.booking-search-bar,
.booking-filter-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  height: 40px;
  font-size: clamp(12px, 3vw, 14px);
}
.booking-search-bar {
  width: 300px;
}

.booking-add-button {
  padding: 8px 9px;
  width: 140px;
  background-color: #22326e;
  color: white;
  border: none;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: clamp(14px, 3vw, 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: 0.3s all ease-in;
}

.booking-table-row-hover {
  cursor: pointer;
}

.booking-table-row-hover:hover {
  background: rgb(0, 0, 0, 0.1);
}

.admin-booking-table td {
  text-align: left;
  padding: 10px;
}

.admin-booking-table th {
  text-align: left;
  padding: 10px;
}

.agency-modal-overlay{
  z-index: 1001!important;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .booking-table-title {
    font-size: 1.5rem; 
  }

  .booking-table-controls {
    flex-direction: column; 
    align-items: center;
  }

  .booking-search-bar,
  .booking-filter-select {
    margin-right: 0; 
    margin-bottom: 10px; 
  }

  .booking-table th,
  .booking-table td {
    font-size: 0.9rem; 
  }
}

@media (max-width: 480px) {
  .booking-table-title {
    font-size: 1.2rem; 
  }

  .booking-pagination button {
    padding: 6px 12px;
  }
}

.password-container {
  position: relative;
}

.admin-password-toggle {
  position: absolute;
  right: 10px; 
  top: calc(50% - 11px);
  cursor: pointer;
  font-size: 1.2rem;
  color: #979bb4;
}
