*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: thin;
}

.drukairkFooter {
  background-color: #22326e;
  color: #fff;
  position: absolute;
  margin-bottom: 0;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif sans-serif;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
}

.drukairkFooter__container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px 20px;
  gap: 20px;
  position: relative;
}

.drukairkFooter__section {
  min-width: 200px;
}

.drukairkFooter__section:nth-child(1) {
  grid-column: 1;
}

.drukairkFooter__section:nth-child(2) {
  grid-column: 2;
}

.drukairkFooter__section:nth-child(3) {
  grid-column: 3;
}

.drukairkFooter__section:nth-child(4) {
  grid-column: 4;
}

.drukairkFooter__section:nth-child(5) {
  grid-column: 2;
  grid-row: 2;
  margin-top: -85px;
}

.drukairkFooter__socialLinks {
  position: absolute;
  top: 146px; 
  right: 113px; 
  display: flex;
  flex-direction: column;
}

.drukairkFooter__sectionTitle {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 12px;
  letter-spacing: -0.002em;
}

.drukairkFooter__sectionTitle::after {
  content: "";
  background: #fff;
  display: block;
  position: relative;
  height: 1.2px;
  width: 90px;
  z-index: 1;
  margin-top: 8px;
  margin-bottom: 8px;
}

.drukairkFooter__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.drukairkFooter__listItem {
  margin-bottom: 8px;
}

.drukairkFooter__listItem a {
  color: #fff;
  text-decoration: none;
  transition: color 0.5s;
}

.drukairkFooter__listItem a:hover {
  opacity: 0.8;
}

.drukairkFooter__socialTitle {
  display: flex;
  margin-bottom: 12px;
  font-size: 20px;
  margin-left: 0;
  text-align: left;
  font-weight: 400;
}

.drukairkFooter__socialIconsContainer {
  display: flex;
  gap: 15px;
}

.drukairkFooter__socialLink {
  color: #fff;
  font-size: 20px;
  transition: color 0.5s;
}

.drukairkFooter__copyright {
  text-align: left;
  font-size: 15px;
  padding: 15px 0;
  padding-left: 19%;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  color: grey;
}

@media (max-width: 992px) {
  .drukairkFooter__container {
    grid-template-columns: repeat(3, 1fr);
  }

  .drukairkFooter__section:nth-child(4) {
    grid-column: 3;
    grid-row: 1;
  }

  .drukairkFooter__section:nth-child(5) {
    margin-top: -15px;
  }

  .drukairkFooter__socialLinks {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 20px;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .drukairkFooter__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .drukairkFooter__section:nth-child(3),
  .drukairkFooter__section:nth-child(4) {
    grid-column: auto;
  }

  .drukairkFooter__section:nth-child(5) {
    grid-column: 2;
    grid-row: 2;
    margin-top: -15px;
  }
}

@media (max-width: 576px) {
  .drukairkFooter__container {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .drukairkFooter__section {
    grid-column: 1 !important;
    grid-row: auto !important;
    text-align: center;
    margin-top: 0;
  }

  .drukairkFooter__sectionTitle::after {
    margin-left: auto;
    margin-right: auto;
  }

  .drukairkFooter__socialLinks {
    position: static;
    margin-top: 20px;
    align-items: center;
  }
}

@media (max-width: 426px) {
  .drukairkFooter__container {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .drukairkFooter__section {
    grid-column: 1 !important;
    grid-row: auto !important;
    text-align: left;
    margin-top: 0;
  }

  .drukairkFooter__sectionTitle::after {
    margin-left: 0;
    margin-right: 0;
  }

  .drukairkFooter__socialLinks {
    align-items: flex-start;
  }
}