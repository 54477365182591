.admin-add-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  border: #000;
}

.admin-table-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
}

.admin-table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-search-bar {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-add-button {
  padding: 8px 9px;
  width: 140px;
  background-color: #22326e;
  color: white;
  border: none;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  font-size: clamp(14px, 3vw, 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: 0.3s all ease-in;
}

.download-table-xls-button:hover {
  filter: brightness(0.8);
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}

.admin-table th,
.admin-table td {
  padding: 12px 15px;
  text-align: left;
}

.admin-table th {
  background-color: #f4f4f4;
  text-align: left;
}

.admin-action-icons {
  text-align: center;
}

.admin-delete-button,
.admin-edit-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.admin-delete-button svg {
  color: red;
}

.admin-edit-button svg {
  color: green;
}

.admin-pagination {
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.admin-pagination button {
  padding: 8px 12px;
  margin: 0 4px;
  border: none;
  background-color: #eaeaea;
  cursor: pointer;
}

.admin-pagination .active-page {
  background-color: #2c3e50;
  color: white;
}

.admin-results-count {
  text-align: right;
  margin-top: 10px;
}
.super-modal-content h3,
.super-modal-content .modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.super-modal-content {
  background-color: white;
  padding: 20px;
  width: 730px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.admin-form {
  margin-top: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #5a607f;
  margin-bottom: 5px;
}
.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  height: 50px;
}
.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #22326e;
}
.form-group textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: none;
}

.admin-submit-button {
  background-color: #22326e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 48%;
  text-align: center;
}

.admin-submit-button:hover {
  background-color: #1b274d;
}

.divider {
  border: 1px solid lightgray;
  margin: 15px 0;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-column-left,
.form-column-right {
  width: 48%;
}

@media screen and (max-width: 768px) {
  .admin-add-button {
    width: 100%;
    text-align: center;
  }

  .form-columns {
    flex-direction: column;
  }

  .form-column-left,
  .form-column-right {
    width: 100%;
  }

  .admin-search-bar {
    width: 100%;
  }

  .admin-table th,
  .admin-table td {
    padding: 8px 10px;
    font-size: 14px;
  }

  .admin-pagination {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .modal-close-button {
    font-size: 20px;
  }

  .admin-table {
    width: 100%;
    overflow-x: auto;
    align-items: center;
  }

  .admin-table th,
  .admin-table td {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
    position: relative;
    border: none;
  }

  .admin-table th::before,
  .admin-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    text-align: left;
    font-weight: bold;
    padding-left: 10px;
  }

  .admin-pagination {
    justify-content: center;
    margin-top: 10px;
  }
}

@media screen and (max-width: 425px) {
  .admin-table-controls {
    flex-direction: column;
    gap: 10px;
  }

  .admin-search-bar {
    width: 100%;
  }

  .admin-add-button {
    width: 100%;
  }
}
.password-container {
  position: relative;
}

.admin-add-password-toggle {
  position: absolute;
  right: 10px; 
  top: calc(50% - 11px);
  cursor: pointer;
  font-size: 1.2rem;
  color: #979bb4;
}
