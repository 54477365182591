.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #22326E;
}

.forgot-password-form {
  display: flex;
  border: 0.5px solid #FFFFFF;
  background-color: #22326E;
  width: 1100px;
  height: 600px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.forgot-left-side {
  background-color: #FFFFFF;
  padding: 40px; 
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  border-right: 1px solid #FFFFFF;
}

.forgot-right-side {
  background-color: #22326E;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.forgot-logo img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 400px; 
}

.forgot-title {
  margin-bottom: 50px;
  font-size: 36px;
  color: #22326E;
  text-align: center;
  font-weight: bold;
 
}


.forgot-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #22326E;
  border-radius: 5px;
  font-size: 16px;
  color: #22326E;
}

.forgot-button {
  width: 100%;
  padding: 10px;
  background-color: #22326E;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin: 20px 0;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.forgot-button:hover {
  /* background-color: #E35205; */
  filter: brightness(0.8);

}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


@media (max-width: 768px) {
  .forgot-password-form {
    flex-direction: column;
    width: 90%; 
  }

  .forgot-left-side, .forgot-right-side {
    width: 100%;
  }

  .forgot-right-side {
    padding: 20px;
  }

  .forgot-logo img {
    max-height: 200px; 
  }
}
