.otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #22326E;
  }
  
  .otp-form-container {
    display: flex;
    border: 0.5px solid #FFFFFF;
    background-color: #22326E;
    width: 1100px;
  height: 600px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .otp-left-side {
    background-color: #FFFFFF;
    padding: 40px; 
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    border-right: 1px solid #FFFFFF;
  }
  
  .otp-right-side {
    background-color: #22326E;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .otp-logo img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 400px; 
  }
  
  .otp-title {
    margin-bottom: 60px;
    font-size: 36px;
    color: #22326E;
    text-align: center;
    font-weight: bold;
  }
  
  .otp-input-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .otp-input {
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: 0 5px;
    border: 1px solid #22326E;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    color: #22326E;
  }
  
  .otp-submit-button {
    width: 100%;
    padding: 10px;
    background-color: #22326E;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin: 20px 0;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .otp-submit-button:hover {
    /* background-color: #E35205; */
    filter: brightness(0.8);

  }
  
  .otp-error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .otp-form-container {
      flex-direction: column;
      width: 90%; 
    }
  
    .otp-left-side, .otp-right-side {
      width: 100%;
    }
  
    .otp-right-side {
      padding: 20px;
    }
  
    .otp-logo img {
      max-height: 200px; 
    }

  

  }
  