.container {
  max-width: 95%;
  padding: 20px;
  text-align: center;
}

.controls-container {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.controls-container-top{
  justify-content: space-between;
}


.right-dropdown-container{
  display: flex;
}

.left-dropdown-container{
  color: black;
  display: flex;
}

.booking-update-bar{
  width: 150px !important;
}

.dropdown-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

label {
  font-size: 1.1em;
  margin-right: 10px;
}

select {
  padding: 8px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.download-container {
  display: flex;
  align-items: center;
}

.download-btn {
  background-color: #22326E;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.download-btn:hover {
  filter: brightness(0.8);
}

.chart-container {
  margin: 20px 0;
  height: 500px;
  width: 100%;
}

/* Table for Excel Export */
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  text-align: center;

}

thead th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}


/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .chart-container {
    height: 300px;
  }

  select {
    width: 100%;
  }

  h2 {
    font-size: 1.6em;
  }
}


@media (max-width: 480px) {
  .chart-container {
    height: 250px;
  }

  h2 {
    font-size: 1.4em;
  }

  select {
    width: 100%;
    font-size: 0.9em;
  }

  .download-table-xls-button {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}