*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: 'Optima LT Pro';

}

