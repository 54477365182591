.user-signup-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #22326E;
  color: white;
  min-height: 100vh;
}

.user-signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

.user-signup-form {
  display: flex;
  border: 1px solid #FFFFFF;
  background-color: #22326E;
  width: 100%;
  max-width: 1100px;
  height: 700px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.user-left-side {
  background-color: #FFFFFF;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.user-signup-title {
  font-size: 36px;
  color: #22326E;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.user-label {
  color: #22326E;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.user-signup-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #22326E;
  border-radius: 5px;
  font-size: 16px;
  color: #22326E;
}

.user-signup-button {
  width: 100%;
  padding: 10px;
  background-color: #22326E;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.user-signup-button:hover {
  filter: brightness(0.8);
}

.user-login-text {
  color: #22326E;
  font-size: 14px;
  text-align: left;
}

.user-login-link {
  color: #22326E;
  font-weight: bold;
  text-decoration: underline;
}

.user-right-side {
  background-color: #22326E;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.user-signup-logo img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 400px;
}

.password-container {
  position: relative;
}

.signup-password-toggle {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #979bb4;
}

.user-signup-footer {
  background-color: #22326E;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 14px;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.signup-password-toggle {
  position: absolute;
  right: 30px;  
  top: calc(76% - 22px);
  cursor: pointer;
  font-size: 1.2rem;
  color: #979bb4;
}

@media (max-width: 768px) {
  .user-signup-form {
    flex-direction: column;
    width: 90%;
    height: auto;
  }

  .user-left-side,
  .user-right-side {
    width: 100%;
  }

  .user-left-side {
    padding: 20px;
  }

  .user-signup-logo img {
    max-height: 200px;
  }

  .user-signup-title {
    font-size: 28px;
  }

  .user-signup-input {
    font-size: 14px;
  }

  .user-signup-button {
    font-size: 14px;
    padding: 8px;
  }

  .signup-password-toggle {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .user-signup-title {
    margin-top: 40px;
    font-size: 24px;
  }

  .user-signup-input {
    font-size: 12px;
  }

  .user-signup-button {
    font-size: 12px;
    padding: 6px;
  }

  .signup-password-toggle {
    font-size: 0.9rem;
  }
}
