.booking-container {
  width: 90%;
  background-color: #fff;
  padding: 1rem 2rem;
  overflow-x: hidden;
  border-radius: 5px;
  border: 1px solid #C9C9C9;
  position: relative;
  margin: 0 auto;
  margin: 50px auto;
}

.booking-progressContainer {
  display: flex;
  justify-content: space-between;
  margin: 0rem auto;
  position: relative;
  width: 90%;
  margin-bottom: 1rem;
}

.booking-progressContainer::before {
  content: "";
  position: absolute;
  background-color: #ddd;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
}

.booking-progress {
  position: absolute;
  background: #1E306D;
  height: 1.5px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: all 0.8s ease-in-out;
  width: 100%;
  overflow: hidden;
}

.booking-progress div {
  height: 100%;
  background: #E35205;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 2px solid #1E306D;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  font-size: 45px;
  color: #1E306D;
}

.circle.active {
  border: 1px solid #E35205;
  color: #E35205;
}

.booking-btns {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.booking-btns .booking-btn,
.disabled {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #1E306D;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 20px;
  width: 170px;
  font-family: "Normal", sans-serif;
  font-size: 14px;
}

.btn-text {
  position: relative;
  top: 4%;
}

.booking-btn .active {
  outline: none;
  transform: scale(0.98);
}

.disabled {
  outline: none;
  border: none;
  color: black;
  cursor: not-allowed;
  background: #ddd;
}

.booking-form-container {
  width: 90%;
  margin: 0 auto;
}

.booking-form-container h2 {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  font-size: clamp(18px, 3vw, 22px);
  font-family: 'DemiBold';
  color: #1F1F1E;
}

.booking-form-container .booking-form-group {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1.6rem;
  display: flex;
  gap: 0 5%;
}

.booking-form-container form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2rem;
  font-family: "Roman";
  color: #1F1F1E;
  font-size: clamp(12px, 3vw, 14px);
}

.booking-form-container form input,
.booking-form-container form select {
  border: 1px solid #D9E1EC;
  padding: 0.75rem 0.5rem;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  font-size: clamp(12px, 3vw, 14px);
}

.booking-form-container form select {
  padding: 0 0.5rem;
}

.helipadPermission {
  display: flex;
  gap: 50px;
}

.helipadPermission .radio-label {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
}

.booking-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #FFFFFF;
  color: black;
  font-size: 20px;
}

.booking-icon-prev {
  transform: rotate(180deg);
}

.error {
  color: red;
  font-size: 12px;
}

.checkbox-layap-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-layap-group label {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  gap: 14px !important;
  font-weight: bold;
  font-size: 14px !important;

}

.checkbox-layap-group label input {
  width: auto;
}

.checkbox-layap-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Passenger */

.passsenger-weight {
  font-size: clamp(14px, 3vw, 16px);
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 1.6rem;
  font-family: "Medium";
}

.passsenger-count {
  color: #1F1F1E;
  font-size: clamp(14px, 3vw, 18px);
  font-family: "Normal";
  margin-bottom: 1.2rem;
}

.passengerWrapper {
  animation: fade-in 0.5s ease-in-out;
  opacity: 1;
  margin: 15px 0;
}

.passengerWrapper.fade-in {
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.booking-form-topic-price{
  display: flex;
  justify-content: space-between;
}

.booking-form-topic-price .price {
  flex-grow: 1;      
  text-align: right;  
  white-space: nowrap; 
  text-overflow: ellipsis; 
  margin-right: 20px;
}
.passenger-btn {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  border: 1.5px solid #1E306D;
  color: #1E306D;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 10px;
  width: 180px;
  font-family: "Normal";
  font-size: 14px;
}

.passenger-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1.5px solid #1E306D;
  color: #1E306D;
  font-size: 20px;
  background: transparent;
}

/* Payment */

.payment-container {
  width: 90%;
  margin: 50px auto;
  height: auto;
}

.payment-text-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #1E306D;
  font-family: 'Normal';
  font-size: clamp(16px, 3vw, 18px);
  margin-bottom: 8px;
}

.payment-card-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.payment-card {
  height: 250px;
  width: calc(50% - 10px);
  border: 2px solid #1E306D;
  box-shadow: 0 4px 8px rgba(30, 48, 109, 0.5);
  transition: 0.3s all ease-in-out;
}

.payment-card:hover {
  transform: scale(1.05);
}

.payment-card-content {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.card-first-img-payment {
  height: 67px;
  width: auto;
  max-width: 100%;
}

.payment-topic,
.payment-des {
  font-size: clamp(14px, 3vw, 16px);
  color: #1E306D;
  font-family: "DemiBold";
  margin-top: 10px;
}

.payment-des {
  font-family: "Roman";
  margin: 20px 0;
}

.card-first-second-payment {
  height: 20px;
  width: auto;
  max-width: 100%;
}


@media screen and (max-width: 1024px) {
  .payment-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* popupcustom button */
.custom-confirm-button {
  border: 2px solid #1E306D !important;
}

.custom-deny-button {
  border: 2px solid #1E306D !important;
  color: #1E306D !important;
  background: transparent !important;
}

@media screen and (max-width: 768px) {
  .booking-container {
    width: 95%;
    padding: 1rem;
  }

  .booking-progressContainer {
    width: 100%;
  }

  .circle {
    width: 45px;
    height: 45px;
    font-size: 24px;
  }

  .booking-btns {
    width: 100%;
    gap: 10px;
  }

  .booking-btns .booking-btn,
  .disabled {
    width: 120px;
    font-size: 12px;
  }

  .booking-form-container {
    width: 100%;
  }

  .booking-form-container .booking-form-group {
    flex-direction: column;
    gap: 1rem;
  }

  .booking-form-container form input,
  .booking-form-container form select {
    font-size: 14px;
  }

  .helipadPermission {
    gap: 20px;
  }

  .passenger-btn {
    width: 25%;
    height: 60%;
    font-size: 10px;

  }
  .passenger-icon-container{
    margin-top: 2px;
    margin-bottom: 2px;
    height: 20px;
    width: 20px;
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .booking-container {
    padding: 0.5rem;
  }

  .circle {
    width: 45px;
    height: 45px;
    font-size: 25px;
  }

  .booking-btns .booking-btn,
  .disabled {
    width: 100px;
    font-size: 10px;
    gap: 5px;
  }

  .booking-icon-container {
    height: 18px;
    width: 18px;
    font-size: 16px;
  }

  .booking-form-container h2 {
    font-size: 18px;
  }

  .booking-form-container form label {
    font-size: 12px;
  }

  .booking-form-container form input,
  .booking-form-container form select {
    font-size: 12px;
  }

  .passsenger-weight,
  .passsenger-count {
    font-size: 14px;
  }
  .passenger-btn {
    width: 53%;
    margin-bottom: 10px;
    height: 60%;
    font-size: 10px;

  }
  .passenger-icon-container{
    margin-top: 2px;
    margin-bottom: 2px;
    height: 20px;
    width: 20px;
    font-size: 15px;
  }
}