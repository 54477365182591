.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #22326E;
  }
  
  .change-password-form {
    display: flex;
    border: 0.5px solid #FFFFFF;
    background-color: #22326E;
    width: 1100px;
  height: 600px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .change-left-side {
    background-color: #FFFFFF;
    padding: 40px; 
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    border-right: 1px solid #FFFFFF;
  }
  
  .change-right-side {
    background-color: #22326E;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid red;
  }
  
  .change-logo img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 400px; 
  }
  
  .change-title {
    margin-bottom: 50px;
    font-size: 36px;
    color: #22326E;
    text-align: center;
    font-weight: bold;
  }
  
  .change-input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #22326E;
    border-radius: 5px;
    font-size: 16px;
    color: #22326E;
    margin-bottom: 22px;
  }
  
  .change-button {
    width: 100%;
    padding: 10px;
    background-color: #22326E;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .change-button:hover {
    /* background-color: #E35205; */
    filter: brightness(0.8);

  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  
  @media (max-width: 768px) {
    .change-password-form {
      flex-direction: column;
      width: 90%; 
    }
  
    .change-left-side, .change-right-side {
      width: 100%;
    }
  
    .change-right-side {
      padding: 20px;
    }
  
    .change-logo img {
      max-height: 200px; 
    }
  }
  