.profile-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px;
    background-color: #FFFFFF;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-color: #000;
}

:root {
    --primary-color: #22326e;
    --secondary-color: #ff9800;
    --text-color: #4f4f4f;
    --line-color: rgba(0, 0, 0, 0.2);
    --dark-text: #000000;
}

/* Page Structure */
.profile-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Main Content */
.profile-main-content {
    flex: 1;
    padding: 20px;
    background-color: inherit; /* Use parent background color */
    display: flex;
    flex-direction: column;
    align-items: center; 
}

/* Title and Underline aligned to the left */
.profile-title {
    font-size: 28px;
    font-weight: bold;
    text-align: left; 
    margin-top: 10px;
    width: 100%; 
    max-width: 900px;
}

.profile-underline {
    display: block;
    width: 60px; 
    height: 2px;
    background-color: var(--secondary-color);
    margin-left: 0; 
}

/* Profile Box */
.profile-box {
    width: 100%; 
    max-width: 900px;
    border: 1px solid black;
    padding: 30px;
    background-color: inherit;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.profile-initials-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #1E306D;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-initials {
    font-size: 70px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
}

/* Thin Line */
.profile-thin-line {
    border: none;
    border-top: 1px solid var(--line-color);
    margin: 20px 0;
}

/* Details Section */
.profile-details-header {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    color: #000000;
}

/* Field Rows */
.profile-field-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 20px;
}

/* Profile Field */
.profile-field {
    flex: 1;
    text-align: left;
}

.profile-field label {
    display: block;
    font-weight: 400;
    margin-bottom: 5px;
    color: var(--text-color);
}

.profile-field input {
    width: 100%;
    padding: 8px;
    border: 1px solid black;
    border-radius: 4px;
    outline: none;
}

/* Update Profile Button */
.profile-update-btn {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-update-btn:hover {
    background-color: rgba(34, 50, 110, 0.8);
}

/* Change Password Link */
.profile-change-password {
    margin-top: 10px;
    text-align: left;
}

.profile-change-password a {
    text-decoration: underline;
    font-weight: bold;
    color: var(--dark-text);
}

/* Popup Modal */
.profile-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%; 
    max-width: 500px; 
    height: 90%;
    max-height: 400px; 
    position: relative;
    overflow-y: auto; 
}

.service-modal-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  border: none;
}
.profile-popup-body{
    margin-top: 60px;
}

.profile-popup-body input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid black;
    border-radius: 4px;
}

/* Save Changes Button */
.profile-save-changes-btn {
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-save-changes-btn:hover {
    background-color: rgba(34, 50, 110, 0.8);
}

/* Responsive Design */
@media (max-width: 768px) {
    .profile-title {
        font-size: 24px; 
    }

    .profile-box {
        width: 95%; 
        padding: 20px; 
    }

    .profile-popup {
        width: 90%; 
        max-width: 350px; 
    }

    .profile-field-row {
        flex-direction: column; 
    }

    .profile-field {
        margin: 10px 0; 
    }
}

@media (max-width: 480px) {
    .profile-title {
        font-size: 20px; 
    }

    .profile-popup {
        padding: 15px; 
    }

    .profile-popup-header h2 {
        font-size: 18px; 
    }

    .profile-field input {
        padding: 6px; 
    }

    .profile-save-changes-btn, 
    .profile-update-btn {
        padding: 8px; 
    }
}

.password-container {
  position: relative;
}

.profile-password-toggle {
  position: absolute;
  right: 10px; 
  top: calc(50% - 18px);
  cursor: pointer;
  font-size: 1.2rem;
  color: #979bb4;
}
.profile-popup-header .form-title {
  padding-bottom: 20px;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 1px solid #22326E;
}