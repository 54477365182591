/* Sidebar */
#superadmin_sidebar {
    height: 100%;
    background-color: #22326E;
    overflow-y: auto;
    transition: all 0.5s;
    position: fixed;
    width: 296px;
  }
  
  .logo_header {
    width: 199px;
    height: 151px;
  }
  
  .superadmin-sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0 30px;
    margin-bottom: 30px;
  }
  
  .superadmin-sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .superadmin-sidebar-list {
    padding: 0;
    list-style-type: none;
    text-align: center;
    margin-top: 100px;
  }
  
  .superadmin-sidebar-list-item {
    padding: 15px 0;
    margin-bottom: 14px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
  }
  
  .superadmin-sidebar-list-item:hover {
    filter: brightness(0.6);
  }
  
  .superadmin-sidebar-list-item > a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 40px;
    gap: 20px;
    width: 100%;
  }
  
  .superadmin-sidebar-list-item.active {
    background-color: #E35205;
    cursor: pointer;
    border-radius: 0 50px 50px 0;
  }
  
  .superadmin-sidebar-responsive {
    display: inline;
    position: absolute;
    z-index: 12;
  }
  
  .superadmin-line {
    border: 0;
    border-top: 1px solid #fff;
    margin: 100px 0;
  }
  
  /* Adjust as necessary for other sections */
  
  /* Responsive Media Queries */
  @media screen and (max-width: 992px) {
    #superadmin-sidebar {
      display: none;
    }
  
    .menu-icon {
      display: inline;
    }
  }
  
  @media screen and (max-width: 768px) {
    .admin-cards {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
  