.feedback-Wrapper {
    display: flex;
    align-items: center;
    height: auto;
    width: 90%;
    max-width: 1200px;
    margin: 70px auto;
    gap: 50px;
    position: relative;
}

.topLeftCircle {
    position: absolute;
    border: 10px solid #E35205;
    height: 100px;
    width: 100px;
    background: transparent;
    border-radius: 50%;
    top: -30px;
    right: -50px;
}

.bottomLeftCircle {
    position: absolute;
    border: 8px solid #F6B700;
    height: 50px;
    width: 50px;
    background: transparent;
    border-radius: 50%;
    bottom: 0;
    right: 5%;
}

.FeedbackImgWrapper {
    height: 500px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}

.FeedbackImgWrapper p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(35px, 5vw, 60px);
    font-family: "ExtraBlack";
}

.FeedbackImgWrapper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.feedbackContent {
    flex: 1.5;
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 2;
}

.feedbackContent :nth-child(1) {
    font-size: clamp(20px, 3vw, 26px);
    font-family: "Bold";
    width: 100%;
}

.fedbackBullets {
    font-size: clamp(14px, 2vw, 18px);
    font-family: "DemiBold";
    position: relative;
    color: #303030;
    padding-left: 20px;
}

.fedbackBullets::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #303030;
}

.feedbackBtnPage {
    text-decoration: none;
    width: 150px;
    height: 40px;
    background: #22326E;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: "Roman";
    font-size: clamp(12px, 2vw, 14px);
    transition: all 0.3s ease-in-out;
}

.feedbackBtnPage:hover {
    filter: brightness(0.8);
}

@media screen and (max-width: 1024px) {
    .feedback-Wrapper {
        flex-direction: column;
        gap: 30px;
    }



    .feedbackContent {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .feedback-Wrapper {
        width: 95%;
        margin: 50px auto;
    }

    .FeedbackImgWrapper {
        height: 300px;
    }

    .topLeftCircle, .bottomLeftCircle {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .FeedbackImgWrapper {
        height: 250px;
        flex-direction: column;
    }

    .feedbackContent {
        gap: 20px;
    }

    .feedbackBtnPage {
        width: 100%;
    }
}