.booking-modal-content::-webkit-scrollbar {
    width: 12px; 
}

.booking-modal-content::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px; 
}

.booking-modal-content::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
    border: 3px solid #f1f1f1; 
}

.booking-modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
}

.booking-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.booking-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 95dvh;
    overflow: scroll;
    overflow-x: hidden;
}

.form-title {
font-family: 'Bold';
  padding-bottom: 20px;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 3px solid #D7DBEC;
}

.service-modal-close-button:hover {
    color: rgb(185, 56, 56);
}

.service-modal-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}
.booking-break-header {
    font-family: "DemiBold";
    font-size: clamp(14px, 3vw, 16px);
    margin-bottom: 15px;
}

.passenger-tab-wrapper{
    display: flex;
    gap: 20px;
}

.passenger-tab-wrapper .passenger-tab{
    width: 200px;
    height: 30px;
    margin-bottom: 20px;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E35205;
    border: 2px solid #E35205;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.passenger-tab-wrapper .passenger-tab:hover{
    background: #E35205;
    color: #FFFFFF;
}

.passenger-tab-wrapper .passenger-tab.active{
    background: #E35205;
    color: #FFFFFF;
}

.admin-booking-modal-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    /* border: 1px solid red; */
}

.admin-booking-modal-btn{
    height: 40px;
    width: 45%;
    font-size: clamp(16px,3vw,18px);
    font-family: "Roman";
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.admin-booking-modal-btn:hover{
    filter: brightness(0.8);
}

.admin-booking-modal-btns :nth-child(1){
    background: #22326E;
}

.admin-booking-modal-btns :nth-child(2){
    background: #E35205;
}

.booking-add-btn{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(16px,3vw,18px);
    color: #FFFFFF;
    height: 40px;
    background: #22326E;
    outline: none;
    border: none;
    border-radius: 4px;
    font-family: "Roman";
    cursor: pointer;
}

.screenshot-wrapper{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
}
.screenshot-img{
    width: 200px;
    height: 250px;
    border-radius: 5px;
    object-fit: cover;
}

.admin-booking-add-form-container{
    width: 60%;
}

.admin-schedule-modal-btn{
    background: #22326E;
}