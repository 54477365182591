.feedback-admin-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px;
    background-color: #ffffff;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    border: #000;
  }
  
  .feedback-table-container {
    width: 90%;
  }
  
  .feedback-table-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .feedback-table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .feedback-search-bar {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .feedback-add-button {
    background-color: #22326e;
    color: white;
    padding: 10px 23px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    text-align: left;
    margin-left: 0;
  }
  
  .feedback-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
  }
  
  .feedback-table th,
  .feedback-table td {
    padding: 12px 15px;
  }
  
  .feedback-table th {
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .feedback-action-icons {
    text-align: center;
  }
  
  .feedback-delete-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  .feedback-delete-button svg {
    color: red;
  }
  
  .feedback-pagination {
    display: flex;
    justify-content: left;
    margin-top: 20px;
  }
  
  .feedback-pagination button {
    padding: 8px 12px;
    margin: 0 4px;
    border: none;
    background-color: #eaeaea;
    cursor: pointer;
  }
  
  .feedback-pagination .active-page {
    background-color: #2c3e50;
    color: white;
  }
  
  .feedback-results-count {
    text-align: right;
    margin-top: 10px;
  }
  
  /* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
}

/* Close Button */
.modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    color: #555;
    cursor: pointer;
}

/* Form Header */
.modal-content h3 {
    margin: 0 0 20px;
    font-size: 20px;
    color: #22326e;
    text-align: center;
}

/* Form Group */
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
    color: #333;
}

/* Input Fields */
.feedback-form input[type="text"],
.feedback-form input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.feedback-form input[type="text"]:focus,
.feedback-form input[type="number"]:focus {
    border-color: #22326e;
    outline: none;
}

/* Submit Button */
.feedback-submit-button {
    background-color: #22326e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
    transition: background-color 0.3s;
}

.feedback-submit-button:hover {
    background-color: #1b274d;
}

/* Divider */
.divider {
    border: 1px solid lightgray;
    margin: 20px 0;
}
.options-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px; /* Space between questions and options */
}

.options-column {
    flex: 1;
    margin-right: 20px; /* Space between the two columns */
}

.options-column:last-child {
    margin-right: 0; /* Remove margin from the last column */
}

.form-group {
    margin-bottom: 16px; /* Space between input fields */
}
