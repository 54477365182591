.userHeader {
  background-color: #22326e;
}

a {
  text-decoration: none;
}

.header-content-wrapper {
  width: 90%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0; 
  font-family: "Optima LT Pro", Arial, sans-serif;
  position: relative;
  overflow: visible;
  cursor: pointer;
}

.header-logo {
  height: 100px;
  width: auto;
  position: absolute;
  left: 0;
  top: 75%;
  transform: translateY(-50%);
}

.header-title {
  font-size: 24px;
  margin: 0;
  text-align: center;
  z-index: 1;
  color: #FFFFFF;
}

.initials-container {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  color: white;
  border: 1px solid #FFFFFF;
}

.header-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.header-button-container a {
  text-decoration: none;
  font-size: clamp(14px, 3vw, 16px);
  height: 40px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E35205;
  font-family: "Medium";
  transition: all 0.3s ease-in-out;
}

.header-button-container a:nth-child(2) {
  background-color: #E35205;
}

.header-button-container a:nth-child(1):hover {
  background: #E35205;
}

.header-button-container a:nth-child(2):hover {
  background: transparent;
}

.initials-circle {
  width: 40px;
  height: 40px;
  background-color: white;
  color: #22326e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #22326e;
  border: solid 1px white;
  list-style: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1000;
  left: -117px;
  text-align: center;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  color: white;
  border: 1px solid #FFFFFF;
}

@media (max-width: 768px) {
  .header-title {
    display: none;
  }

  .header-button-container {
    flex-direction: row;
    gap: 10px;
  }

  .header-button-container a {
    width: 100px;
    height: 35px;
    font-size: 14px;
  }

  .header-logo {
    height: 80px;
    top: 50%;
  }

  .header-content-wrapper {
    padding: 25px 0;
  }
}

@media (max-width: 480px) {
  .header-logo {
    height: 60px;
  }

  .header-button-container a {
    width: 90px;
    height: 30px;
    font-size: 12px;
  }

  .header-content-wrapper {
    padding: 25px 0;
  }
}

@media (max-width: 300px) {
  .header-logo {
    display: none;
  }

  .header-button-container {
    flex-direction: row;
    gap: 5px;
    padding-right: 40px;
  }

  .header-button-container a {
    width: 80px;
    height: 25px;
    font-size: 10px;
  }

  .header-content-wrapper {
    padding: 25px 0;
  }

  .initials-circle {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}